import { Controller } from "stimulus"
import Player from '@vimeo/player'

export default class extends Controller {
  static targets = ['chapters', 'player', 'chapterform', 'time', 'seconds']
  static values = { url:String, id:String, mute:Boolean }


  connect() {
    let _this = this
    this.player()
    this.checkVolume();
    this.player().getDuration().then(function(duration) {
      if(duration > 1){
      _this.getChapters();
    }});
    this.player().on('seeked',function(data) {
      _this.setTime(data.seconds)
    })
  }

  checkVolume(){
    if(this.muteValue) {
      this.player().setVolume(0);
    }else{
      this.player().setVolume(0.8)
    }
  }

  player(){
    if(this._player == undefined){
     let options = { id: this.urlValue };
     this._player = new Player(this.playerTarget, options);
    }
    return this._player
  }

  getChapters() {
    let _this = this
    if (this.chaptersTargets.length >= 1){
      this.player().getChapters().then(function(chapters) {
        if (chapters.length >= 1){
        for (const chapter of chapters){
          _this.chaptersTarget.insertAdjacentHTML('beforeend', '<span class="chapters" data-action="click->player--vimeo#setPosition" data-time="'+chapter.startTime+'">'+chapter.title+'</span>')
        }
        }else  {
          _this.makeChaptersFromVisible();
        }
      }).catch(function(error) {
        console.log(error)
      });}
  }

  makeChaptersFromVisible(){
    if(this.chapterformTargets.length >=1){
      this.chapterformTarget.classList.remove('hidden')
    }
  }

  setTime(time){
    console.log('Setting time' + time)
    if(this.chapterformTargets.length >=1){
      var date = new Date(0);
      date.setSeconds(time); // specify value for SECONDS here
      var timeString = date.toISOString().substr(11, 8);
      console.log(timeString)
      this.timeTarget.value= timeString
      this.secondsTarget.value = time
    }
  }

  setPosition(e) {
    console.log(e.target.dataset.time)
    this.player().setCurrentTime(e.target.dataset.time).then(function(seconds) {
      console.log('trying to set time')
    }).catch(function(error) {
      switch (error.name) {
        case 'RangeError':
            console.log(error)
            break;

        default:
            // Some other error occurred
            break;
      }
    });
  }
}
