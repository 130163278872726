import { Controller } from "stimulus"
import { Chart } from 'chart.js'

export default class extends Controller {
  static targets = [ "output" , 'button']
  static values = {chart:Array, textcolor:String, fontsize:Number, color1:String, color2:String, color3:String, color4:String, color5:String, color6:String, color7:String, color8:String, color9:String, color10:String}

  connect() {
    //this.createChart();

  }

  createChart(){
    this.buttonTarget.classList.add('hidden')
    this.element.style.cursor = 'none'
    this.chart();
  }

  chart(){
    if (this._chart == undefined) {
      this._chart = new Chart(this.outputTarget, {
        type: 'doughnut',
        data: this.chartData(),
        options: this.options()
      })
    } else {
      this._chart.data = this.dataset(data)
      this._chart.options = this.options()
      this._chart.update()
    }
    return this._chart
  }

  sortData(input){
    let data = []
    for (const vote of input) {
      data.push(vote[1])
    }

    console.log(data)
    return data
  }

  sortLabels(input) {
    let data = []
    for (const vote of input) {
      data.push(vote[0])
    }

    console.log(data)
    return data
  }

  chartData(){
    let data = {
      labels: this.sortLabels(this.chartValue),
      datasets: [
        {
          label: 'Dataset 1',
          data: this.sortData(this.chartValue),
          backgroundColor: [this.color1Value, this.color2Value, this.color3Value, this.color4Value, this.color5Value, this.color6Value, this.color7Value, this.color8Value, this.color9Value, this.color10Value],
        }
      ]
    }
    console.log(data.datasets[0].backgroundColor)
    return data
  }
  options(){
    let options = {
          responsive: true,
          animation: {
            easing: 'easeInCirc',
            duration: 3000
          },
          plugins: {
            legend: {
              position: 'top',
              labels: {
                color: this.textcolorValue,
                font: {
                  size: this.fontsizeValue
                }
              },
            },
            title: {
              display: false,
              text: 'Chart.js Doughnut Chart'
            }
        }
    }
    return options
  }




  showLegends(){
    if(this.groupTarget.value == 'hours'){
      return false
    } else {
      return true
    }
  }
}
