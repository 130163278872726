import { Controller } from "stimulus"
import consumer from '../../channels/consumer'
import CableReady from "cable_ready"
export default class extends Controller {
  static values = { id: Number, user: Number }
  static targets = ['chatbox']

  initialize() {
    this.subscription()
    this.scrollToBottom();
  }
  disconnect() {
    this.subscription().unsubscribe()
    this.subscription().disconnected()
  }
  subscription() {
    let id = this.idValue
    let _this = this
    if (this._subscription == undefined) {
      this._subscription = consumer.subscriptions.create({channel: "RoomsChannel", id: id }, {
        connected() {},
        disconnected() {},
        received(data) {
          if (data.cableReady) CableReady.perform(data.operations)
          _this.scrollToBottom();
        }
      })
    }
    return this._subscription
  }
  scrollToBottom() {
    if(this.hasChatboxTarget){
        this.chatboxTarget.scrollTop = this.chatboxTarget.scrollHeight;
    }
  }
}
