import { Controller } from "stimulus"

export default class extends Controller {
  static targets= ['wave1', 'wave2', 'wave3']

  connect(){
    let _this = this
    window.addEventListener('resize', function(event){
    _this.calculateWaveHeight();
  });
  }

  calculateWaveHeight(){
    let height = window.innerHeight;
    this.setHeightWave1(height)
    this.setHeightWave2(height)
    this.setHeightWave3(height)
  }

  setHeightWave1(h){
    console.log('Changing height')
    if (h > 1000) {
      this.wave1Target.setAttribute('height', ((h/100)*55) +'px')
    } else {
      this.wave1Target.setAttribute('height', ((h/100)*30) +'px')
    }
  }

  setHeightWave2(h){
    console.log('Changing height')
    if (h > 1000) {
      this.wave2Target.setAttribute('height', ((h/100)*40) +'px')
    } else {
      this.wave2Target.setAttribute('height', ((h/100)*25) +'px')
    }
  }

  setHeightWave3(h){
    console.log('Changing height')
    if (h > 1000) {
      this.wave3Target.setAttribute('height', ((h/100)*50) +'px')
    } else {
      this.wave3Target.setAttribute('height', ((h/100)*25) +'px')
    }
  }
}
