import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['slider']
    connect() {
      this.setState()
    }

    setState(){
      let state = this.element.dataset.state
      if(state == 'true'){
        this.toggleOn()
      } else {
        this.toggleOff()
      }
    }

    toggleOn(){
      this.element.classList.remove('bg-red-500')
      this.element.classList.add('bg-green-500')
      this.sliderTarget.classList.remove('translate-x-0')
      this.sliderTarget.classList.add('translate-x-5')
      this.element.dataset.state = false
    }
    toggleOff(){
      this.element.classList.remove('bg-green-500')
      this.element.classList.add('bg-red-500')
      this.sliderTarget.classList.remove('translate-x-5')
      this.sliderTarget.classList.add('translate-x-0')
      this.element.dataset.state = true
    }
}
