import { Controller } from "stimulus"

export default class extends Controller {

  backgroundColor(e){ this.setBackgroundColor('content_box', e) }

  textColor(e){ this.setTextStyle('content_box', e) }

  headerColor(e) { this.setTextStyle('content_box_header', e) }

  toggleBoxShadow(){
    let items = document.getElementsByClassName('content_box')
    for (const item of items) {
      item.classList.toggle('shadow-lg')
    }
  }

  toggleBoxInnerShadow(){
    let items = document.getElementsByClassName('content_box')
    for (const item of items) {
      item.classList.toggle('inner_shadow')
    }
  }

  setRadius(e){
    let items = document.getElementsByClassName('content_box')
    for (const item of items) {
      item.style.borderRadius = e.srcElement.value +'px'
    }
  }

  setTextStyle(class_name, element){
    let items = document.getElementsByClassName(class_name)
    for (const item of items) {
      item.style.color = element.srcElement.value
    }
  }

  setBackgroundColor(class_name, element) {
    let items = document.getElementsByClassName(class_name)
    for (const item of items) {
      item.style.backgroundColor = element.srcElement.value
    }
  }

}
