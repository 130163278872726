import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['mobilemenu']

  connect() {

  }

  closeWithKeyboard(e) {
    if (e.keyCode === 27 && !this.mobilemenuTarget.classList.contains('hidden')) {
      this.close();
    }
  }

  close() {
    this.mobilemenuTarget.classList.add('hidden')
  }

  open() {
    this.mobilemenuTarget.classList.remove('hidden')
  }
}
