import { Controller } from 'stimulus'

let timer = null;

export default class extends Controller {

  initialize() {
    this.hide()
  }

  connect() {
    setTimeout(() => {
      this.show()
    }, 200)
  }

  close() {
    this.hide()
    setTimeout(() => {
      this.element.remove()
    }, 1100)

  }

  show() {

    this.element.setAttribute(
      'style',
      "transition: 1s; transform:translate(0, 0);",
    )
    setTimeout(() => {
      this.close();
    }, 10000);
  }

  hide() {
    if (timer) clearTimeout(timer);
    this.element.setAttribute(
      'style',
      "transition: 1s; transform:translate(400px, 0);",
    )
  }
}
