import { Controller } from 'stimulus'

let timer = null;

export default class extends Controller {
  static targets = ['button', 'answer']

  toggle(){
    if(this.answerTarget.classList.contains('hidden')) {
      this.show();
    } else {
      this.hide();
    }
  }

  show() {
    this.answerTarget.classList.remove('hidden')
    this.buttonTarget.classList.remove('-rotate-180')
    this.buttonTarget.classList.add('rotate-0')
  }

  hide() {
    this.answerTarget.classList.add('hidden')
    this.buttonTarget.classList.add('-rotate-180')
    this.buttonTarget.classList.remove('rotate-0')
  }
}
