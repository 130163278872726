import { Controller } from "stimulus"
import consumer from '../../channels/consumer'

// Setter grenser for hvor karte kan dras til innenfor norges grenser
const NORWAY_BOUNDS = {
  north: 81,
  south: 40.07,
  west: 0,
  east: 31.29,
}

// Setter globale variabler
let map, heatmap, heat

export default class extends Controller {
  static targets = [ 'map' ]
  static values = { bgcolor:String, landcolor:String }

  initialize() {
    this.subscription()
    this.connect()
  }
  disconnect() {
    this.subscription().unsubscribe()
    this.subscription().disconnected()
  }
  subscription() {
    let _this = this
    if (this._subscription == undefined) {
      this._subscription = consumer.subscriptions.create('MapChannel', {
        connected() {
        },
        disconnected() {},
        received(data) {
          // Dytter nye cordinater til kartet
          heat.push(new google.maps.LatLng(data.lat, data.long ))
        }
      })
    }
    return this._subscription
  }

  // Lager kart på connect
  connect() {
    heat = new google.maps.MVCArray();
    map = new google.maps.Map(document.getElementById('map'), {
    center: {lat: 65.0, lng: 18.0},
    zoom: 4,
    disableDefaultUI: true,
    restriction: {
      latLngBounds: NORWAY_BOUNDS,
      strictBounds: false,
    },
    });

    map.setOptions({ minZoom: 4.2, maxZoom: 15 });
    let syledMapType = new google.maps.StyledMapType(this.mapStyle())
    map.mapTypes.set('styled_map', syledMapType)
    map.setMapTypeId('styled_map')

    // Henter data fra serveren
    this.getHeatData()
  }

  // Funksjon som tegner data
  drawHeatmap() {
    heatmap = new google.maps.visualization.HeatmapLayer({
    data: heat,
    maxIntensity: 20,
    radius: 20,
    dissipating: true,
    opacity: .9,

    });

    heatmap.setMap(map)
  }

  // Funksjonen som henter dataen
  getHeatData() {
    fetch('/user_positions').then(response => (response.json())).then(positions => this.populateHeatmap(positions))
  }

  // Lager heatmap punkter fra de som allerede er registert på serveren
  populateHeatmap(data){

    for(var i = 0; i < data.length; i++) {
      var obj = data[i];
      heat.push(new google.maps.LatLng(obj.lat, obj.long ))
    }
    this.drawHeatmap()
  }

  mapStyle(){
    let _this = this
    return [
  {
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": this.bgcolorValue
      },
      {
        "visibility": "on"
      },
      {
        "weight": 2
      }
    ]
  },
  {
    "featureType": "administrative.neighborhood",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "landscape.natural",
    "stylers": [
      {
        "color": this.landcolorValue
      },
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "landscape.natural.landcover",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": this.landcolorValue
      }
    ]
  },
  {
    "featureType": "landscape.natural.terrain",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": this.landcolorValue
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": this.bgcolorValue
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": this.bgcolorValue
      }
    ]
  }
]
  }

}
