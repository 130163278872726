import { Controller } from "stimulus"

export default class extends Controller {

  backgroundColor(e){ this.setBackgroundColor('form_input', e) }

  textColor(e){ this.setTextStyle('form_input', e) }

  headerColor(e) { this.setTextStyle('content_box_header', e) }

  toggleBoxShadow(){
    let items = document.getElementsByClassName('form_input')
    for (const item of items) {
      item.classList.toggle('shadow-md')
    }
  }

  toggleInnerShadow(){
    let items = document.getElementsByClassName('form_input')
    for (const item of items) {
      item.classList.toggle('inner_shadow')
    }
  }

  setRadius(e){
    let items = document.getElementsByClassName('form_input')
    for (const item of items) {
      item.style.borderRadius = e.srcElement.value +'px'
    }
  }

  borderColor(e){
    let items = document.getElementsByClassName('form_input')
    for (const item of items) {
      item.style.borderColor = e.srcElement.value
    }
  }

  borderWidth(e){
    let items = document.getElementsByClassName('form_input')
    for (const item of items) {
      item.style.borderWidth = e.srcElement.value +'px'
    }
  }

  setPaddingTopp(e){
    let items = document.getElementsByClassName('form_input')
    for (const item of items){
      item.style.paddingTop = e.srcElement.value + 'px'
    }
  }

  setPaddingBottom(e){
    let items = document.getElementsByClassName('form_input')
    for (const item of items){
      item.style.paddingBottom = e.srcElement.value + 'px'
    }
  }
  setPaddingLeft(e){
    let items = document.getElementsByClassName('form_input')
    for (const item of items){
      item.style.paddingLeft = e.srcElement.value + 'px'
    }
  }

  setPaddingRight(e){
    let items = document.getElementsByClassName('form_input')
    for (const item of items){
      item.style.paddingRight = e.srcElement.value + 'px'
    }
  }

  setTextStyle(class_name, element){
    let items = document.getElementsByClassName(class_name)
    for (const item of items) {
      item.style.color = element.srcElement.value
    }
  }

  setBackgroundColor(class_name, element) {
    let items = document.getElementsByClassName(class_name)
    for (const item of items) {
      item.style.backgroundColor = element.srcElement.value
    }
  }

}
