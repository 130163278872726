import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'iframe', 'url', 'vimeo', 'select', 'mux' ]

  connect() {
    this.selectSource();
  }

  selectSource() {
    switch (this.selectTarget.value) {
      case 'vimeo':
        this.selectVimeo()
        break;
      case 'amazon':
        this.selectUrl()
        break
      case 'mux':
        this.selectMux()
        break
      default:
        this.selectIframe()
        break;
    }
  }

  selectIframe(){
    for (const field of this.iframeTargets) {
      field.classList.remove('hidden')
    }
    this.hideUrl();
    this.hideVimeo();
    this.hideMux();
  }

  selectVimeo(){
    for (const field of this.vimeoTargets) {
      field.classList.remove('hidden')
    }
    this.hideUrl();
    this.hideIframe();
    this.hideMux();
  }

  selectMux(){
    for(const field of this.muxTargets) {
      field.classList.remove('hidden')
    }
    this.hideUrl();
    this.hideIframe();
    this.hideVimeo();
  }

  selectUrl() {
    for (const field of this.urlTargets) {
      field.classList.remove('hidden')
    }
    this.hideVimeo();
    this.hideIframe();
    this.hideMux();
  }

  hideIframe(){
    for (const field of this.iframeTargets ) {
      field.classList.add('hidden')
    }
  }

  hideUrl(){
    for (const field of this.urlTargets ) {
      field.classList.add('hidden')
    }
  }

  hideVimeo(){
    for (const field of this.vimeoTargets ) {
      field.classList.add('hidden')
    }
  }

  hideMux(){
    for(const field of this.muxTargets) {
      field.classList.add('hidden')
    }
  }
}
