import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['content']
  connect() {
    const regex = RegExp(/@[\w]+/g)
    let content = this.contentTarget.innerHTML
    let match = content.match(regex)
    if(match != null ){
      match.forEach(element => {
        content = content.replace(element,`<strong class="chat_mentions">${element}</strong>` )
      });
    }
    this.contentTarget.innerHTML = content
  }
}
