import { Controller } from "stimulus"

export default class extends Controller {

  changeUserColor(e){
    let usernames = document.getElementsByClassName('chat_user')
    for (const user of usernames) {
      user.style.color = e.srcElement.value
    }
  }

  changeTimeColor(e){
    let times = document.getElementsByClassName('chat_time')
    for (const time of times) {
      time.style.color = e.srcElement.value
    }
  }

  changeMentions(e){
    let mentions = document.getElementsByClassName('chat_mentions')
    for (const mention of mentions) {
      mention.style.color = e.srcElement.value
    }
  }

  changeText(e) {
    let texts = document.getElementsByClassName('chat_text')
    for (const text of texts) {
      text.style.color = e.srcElement.value
    }
  }


}
