import { Controller } from "stimulus"

export default class extends Controller {

  setBtnBg(e){
    this.setBackgroundColor('btn_profile', e)
  }

  setHoverBg(e) {
    this.setBackgroundColor('btn_hover', e)
  }

  setText(e) {
    this.setTextStyle('btn_profile', e)
  }

  setTextHover(e) {
    this.setTextStyle('btn_hover', e)
  }

  toggleShadow(e){
    let items = document.getElementsByClassName('btn')
    for (const item of items){
      item.classList.toggle('button_shadow')
    }
  }


  setRadius(e){
    let items = document.getElementsByClassName('btn')
    for (const item of items){
      item.style.borderRadius = e.srcElement.value + 'px'
    }
  }

  setTextSize(e){
    let items = document.getElementsByClassName('btn')
    for (const item of items){
      item.style.fontSize = e.srcElement.value + 'px'
    }
  }

  setPaddingTopp(e){
    let items = document.getElementsByClassName('btn')
    for (const item of items){
      item.style.paddingTop = e.srcElement.value + 'px'
    }
  }

  setPaddingBottom(e){
    let items = document.getElementsByClassName('btn')
    for (const item of items){
      item.style.paddingBottom = e.srcElement.value + 'px'
    }
  }
  setPaddingLeft(e){
    let items = document.getElementsByClassName('btn')
    for (const item of items){
      item.style.paddingLeft = e.srcElement.value + 'px'
    }
  }

  setPaddingRight(e){
    let items = document.getElementsByClassName('btn')
    for (const item of items){
      item.style.paddingRight = e.srcElement.value + 'px'
    }
  }

  setBackgroundColor(class_name, element) {
    let items = document.getElementsByClassName(class_name)
    for (const item of items) {
      item.style.backgroundColor = element.srcElement.value
    }
  }

  setTextStyle(class_name, element){
    let items = document.getElementsByClassName(class_name)
    for (const item of items) {
      item.style.color = element.srcElement.value
    }
  }

}
