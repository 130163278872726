import { Controller } from "stimulus"

export default class extends Controller {
  static targets= ['gradient_from', 'gradient_to', 'gradient_angle', 'gradient_on']
  static values = { shadow: Boolean}

  connect(){
    this.setGradient()
    if(this.shadowValue){
      this.toggleShadow()
    }
  }

  setGradient(){
    let items = document.getElementsByClassName('timer_box')
    if(this.gradient_onTarget.checked){
      let angle = this.gradient_angleTarget.value
      let from = this.gradient_fromTarget.value
      let to = this.gradient_toTarget.value
      for (const item of items) {
        item.style.background = 'linear-gradient('+ angle+ 'deg, '+ from +' 0%, '+ to +' 100%)'
      }
    } else {
      for (const item of items) {
        item.style.background = ''
      }
    }
  }

  setRadius(e){
    let items = document.getElementsByClassName('timer_box')
    for (const item of items){
      item.style.borderRadius = e.srcElement.value + 'px'
    }
  }

  setFontSize(e){
    let items = document.getElementsByClassName('timer_numbers')
    for (const item of items){
      item.style.fontSize = e.srcElement.value + 'px'
    }
  }

  setPaddingTopp(e){
    let items = document.getElementsByClassName('timer_box')
    for (const item of items){
      item.style.paddingTop = e.srcElement.value + 'px'
    }
  }

  setPaddingBottom(e){
    let items = document.getElementsByClassName('timer_box')
    for (const item of items){
      item.style.paddingBottom = e.srcElement.value + 'px'
    }
  }
  setPaddingLeft(e){
    let items = document.getElementsByClassName('timer_box')
    for (const item of items){
      item.style.paddingLeft = e.srcElement.value + 'px'
    }
  }

  setPaddingRight(e){
    let items = document.getElementsByClassName('timer_box')
    for (const item of items){
      item.style.paddingRight = e.srcElement.value + 'px'
    }
  }

  backgroundColor(e){ this.setBackgroundColor('timer_box', e) }

  toggleShadow(){
    let items = document.getElementsByClassName('timer_box')
    for (const item of items){
      item.classList.toggle('drop_shadow')
    }
  }

  textColor(e){ this.setTextStyle('timer_box', e) }
  numberColor(e){ this.setTextStyle('timer_numbers', e) }


  setTextStyle(class_name, element){
    let items = document.getElementsByClassName(class_name)
    for (const item of items) {
      item.style.color = element.srcElement.value
    }
  }

  setBackgroundColor(class_name, element) {
    let items = document.getElementsByClassName(class_name)
    for (const item of items) {
      item.style.backgroundColor = element.srcElement.value
    }
  }

}
