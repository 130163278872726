import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["color", "input"]

  colorChanged(e) {
    this.inputTarget.value = e.srcElement.value
  }
  inputChanged(e) {
    this.colorTarget.value = e.srcElement.value
  }
}
