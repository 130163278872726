import { Controller } from 'stimulus'
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ['form']

  connect() {
    this.timeout = null
    this.duration = this.data.get('duration') || 1000
  }

  save() {
    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      Rails.fire(this.formTarget, 'submit')
    }, this.duration)
  }
}
