import { Controller } from "stimulus"

export default class extends Controller {
  static targets= ['gradient_from', 'gradient_to', 'gradient_angle', 'gradient_on']

  connect(){
    this.setGradient()
  }

  setGradient(){
    let element = document.getElementById('page_body')
    if(this.gradient_onTarget.checked){
    let angle = this.gradient_angleTarget.value
    let from = this.gradient_fromTarget.value
    let to = this.gradient_toTarget.value
    element.style.background = 'linear-gradient('+ angle+ 'deg, '+ from +' 0%, '+ to +' 100%)'
    } else {
      element.style.background = ''
    }
  }

  setLinkText(e){this.setTextStyle('link', e)}

  backgroundColor(e){ this.setBackgroundColor('page_body', e) }

  SecondColor(e){ this.setBackgroundColor('second_color', e) }

  textColor(e){ this.setTextStyle('page_text', e) }

  headerColor(e) { this.setTextStyle('header_text', e) }

  subHeaderColor(e) { this.setTextStyle('sub_header_text', e) }

  footerLink(e) { this.setTextStyle('footer_nav', e) }

  footerhover(e) { this.setTextStyle('footer_nav_hover', e) }

  footerSep(e) { this.setTextStyle('footer_seperator', e) }

  setTextStyle(class_name, element){
    let items = document.getElementsByClassName(class_name)
    for (const item of items) {
      item.style.color = element.srcElement.value
    }
  }

  setBackgroundColor(class_name, element) {
    let items = document.getElementsByClassName(class_name)
    for (const item of items) {
      item.style.backgroundColor = element.srcElement.value
    }
  }

}
