import { Controller } from "stimulus"

export default class extends Controller {
  static values = { token: String, room: String, name: String}
  static targets = ['output']
  initialize() {
    this.createJitsiRoom()
  }

  createJitsiRoom(){
    let token = this.tokenValue
    const options = {

    roomName: this.roomValue,
    parentNode: this.outputTarget,
    jwt: token,
    interfaceConfigOverwrite: {
      DEFAULT_BACKGROUND: '#ffffff',
      HIDE_DEEP_LINKING_LOGO: false,
      SHOW_JITSI_WATERMARK: false,
      DEFAULT_LOGO_URL: 'https://meetando.no/assets/logo-black-36eb2c7572cc0c38f4620e2e7dd360fc9d781b70d8a005d4a816916ba1de1296.svg',
      DEFAULT_WELCOME_PAGE_LOGO_URL: 'https://meetando.no/assets/logo-black-36eb2c7572cc0c38f4620e2e7dd360fc9d781b70d8a005d4a816916ba1de1296.svg',
      DEFAULT_LOCAL_DISPLAY_NAME: this.nameValue,
      HIDE_INVITE_MORE_HEADER: true,
      INITIAL_TOOLBAR_TIMEOUT: 3000,
      TOOLBAR_TIMEOUT: 2000,
      VIDEO_QUALITY_LABEL_DISABLED: true,
      DISPLAY_WELCOME_FOOTER: false,
      DISPLAY_WELCOME_PAGE_ADDITIONAL_CARD: false,
      DISPLAY_WELCOME_PAGE_CONTENT: false,
      SHOW_CHROME_EXTENSION_BANNER: false,
      DISPLAY_WELCOME_PAGE_TOOLBAR_ADDITIONAL_CONTENT: false,
      ENABLE_DIAL_OUT: false,
      TOOLBAR_BUTTONS: [
        'microphone', 'camera', 'fullscreen',
        'fodeviceselection', 'videoquality', 'tileview', 'videobackgroundblur', 'help'
        ],

      },
    configOverwrite: { startWithAudioMuted: false, prejoinPageEnabled: false, hideConferenceSubject: true },
    };
    //let api = new JitsiMeetExternalAPI('8x8.vc/', options)
    let api = new JitsiMeetExternalAPI('meet.meetando.io', options)
    api.executeCommand('displayName', this.nameValue);
  }
}
