import { Controller } from "stimulus"

export default class extends Controller {
  static targets= ['shadow']

  connect(){
    if(this.shadowTarget.checked){
      this.toggleShadow();
    }
  }


  backgroundColor(e){
    this.setBackgroundColor('program_box', e)
  }

  setCurrentBackground(e){
    this.setBackgroundColor('current_program',e)
  }

  setText(e) {
    this.setTextStyle('btn_profile', e)
  }

  setTitleColor(e){
    this.setTextStyle('program_title', e)
  }

  setItemTitleColor(e){
    this.setTextStyle('program_item_title', e)
  }

  setItemTitleSize(e){
    this.setTextSize('program_item_title', e)
  }

  setItemTextColor(e){
    this.setTextStyle('program_text', e)
  }

  setItemTextSize(e){
    this.setTextSize('program_text', e)
  }

  setTimeColor(e){
    this.setTextStyle('program_time', e)
  }

  setTimeFontSize(e){
    this.setTextSize('program_time', e)
  }

  setCarrotColor(e){
    this.setTextStyle('program_carrot', e)
  }

  setMarginBottom(e){
    let items = document.getElementsByClassName('program_box')
    for (const item of items){
      item.style.marginBottom = e.srcElement.value + 'px'
    }
  }

  toggleShadow(e){
    let items = document.getElementsByClassName('program_box')
    for (const item of items){
      item.classList.toggle('program_shadow')
    }
  }

  setRadius(e){
    let items = document.getElementsByClassName('program_box')
    for (const item of items){
      item.style.borderRadius = e.srcElement.value + 'px'
    }
  }

  setPaddingTopp(e){
    let items = document.getElementsByClassName('program_box')
    for (const item of items){
      item.style.paddingTop = e.srcElement.value + 'px'
    }
  }

  setPaddingBottom(e){
    let items = document.getElementsByClassName('program_box')
    for (const item of items){
      item.style.paddingBottom = e.srcElement.value + 'px'
    }
  }
  setPaddingLeft(e){
    let items = document.getElementsByClassName('program_box')
    for (const item of items){
      item.style.paddingLeft = e.srcElement.value + 'px'
    }
  }

  setPaddingRight(e){
    let items = document.getElementsByClassName('program_box')
    for (const item of items){
      item.style.paddingRight = e.srcElement.value + 'px'
    }
  }

  setBackgroundColor(class_name, element) {
    let items = document.getElementsByClassName(class_name)
    for (const item of items) {
      item.style.backgroundColor = element.srcElement.value
    }
  }

  setTextStyle(class_name, element){
    let items = document.getElementsByClassName(class_name)
    for (const item of items) {
      item.style.color = element.srcElement.value
    }
  }

  setTextSize(class_name, element){
    let items = document.getElementsByClassName(class_name)
    for (const item of items) {
      item.style.fontSize = element.srcElement.value + 'px'
    }
  }

}
