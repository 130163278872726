import { Controller } from "stimulus"
import consumer from '../../channels/consumer'
import CableReady from "cable_ready"

export default class extends Controller {
  initialize() {
    this.subscription()
  }
  disconnect() {
    this.subscription().unsubscribe()
    this.subscription().disconnected()
  }
  subscription() {
    if (this._subscription == undefined) {
      this._subscription = consumer.subscriptions.create("AdminChannel", {
        connected() {},
        disconnected() {},
        received(data) {
          if (data.cableReady) CableReady.perform(data.operations)
        }
      })
    }
    return this._subscription
  }

  toggleRooms(){
    this.subscription().perform('toggle_rooms')
  }

  toggleHeatmap(){
    this.subscription().perform('toggle_heatmap')
  }

  toggleBroadcasts(){
    this.subscription().perform('toggle_broadcasts')
  }

  toggleChat(){
    this.subscription().perform('toggle_chat')
  }

  toggleSpeakers(){
    this.subscription().perform('toggle_speakers')
  }

  toggleImageUploads(){
    this.subscription().perform('toggle_image_uploads')
  }

  toggleQuestion(){
    this.subscription().perform('toggle_question')
  }
}
