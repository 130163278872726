import { Controller } from "stimulus"
import videojs from "video.js";
export default class extends Controller {
  static targets = ['player']
  static values = { url:String, id:String }


  connect() {
    let player = videojs(this.playerTarget)
    videojs.options.autoplay = true
  }
}
