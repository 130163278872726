import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["form", "formbox", "response"]

  connect() {

  }

  closeResponse() {
    this.responseTarget.innerHTML = ""
    this.formboxTarget.classList.remove('hidden')
    this.formTarget.reset()
  }
}
