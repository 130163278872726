import { Controller } from "stimulus"
import Sortable from 'sortablejs'
export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      animation: 150,
      onEnd: this.end.bind(this)
    })
  }
  end(event) {
    let url = event.item.dataset.url
    let data = new FormData()
    data.append("position", event.newIndex + 1)
    fetch(url, {
      method: 'PATCH',
      body: data,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    })
  }
}
