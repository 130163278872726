import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['checkin']
  static values = { event: String }

  connect() {
    let event_id = this.eventValue
    this.appendCheckinFrom(window, document, event_id);
  }

  appendCheckinFrom(w, d, event_id) {
    d.event_id = event_id;
    var headTag = d.getElementsByTagName('head')[0];
    var script = d.createElement('script');
    script.src = 'https://registration.checkin.no/registration.loader.js';
    script.async = 1;
    script.crossOrigin = 1;
    headTag.appendChild(script);
  }
}
