import { Controller } from 'stimulus'

export default class extends Controller {

  initialize() {
    this.hide()
  }

  connect() {
    setTimeout(() => {
      this.show()
    }, 50)
  }
  show() {
    this.element.setAttribute(
      'style',
      "transition: 1s; opacity:1;",
    )

  }

  hide() {
    this.element.setAttribute(
      'style',
      "transition: 1s; opacity: 0;",
    )
  }
}
