import { Controller } from "stimulus"
import consumer from '../../channels/consumer'
import CableReady from "cable_ready"
export default class extends Controller {
  static targets = ['chatbox']
  initialize() {
    this.subscription()
    this.scrollToBottom()
  }
  disconnect() {
    this.subscription().unsubscribe()
    this.subscription().disconnected()
  }
  subscription() {
    let _this = this
    if (this._subscription == undefined) {
      this._subscription = consumer.subscriptions.create("ChatChannel", {
        connected() {_this.scrollToBottom();},
        disconnected() {},
        received(data) {
        if (data.cableReady) CableReady.perform(data.operations)
          _this.scrollToBottom();
        }
      })
    }
    return this._subscription
  }
  scrollToBottom() {
    this.chatboxTarget.scrollTop = this.chatboxTarget.scrollHeight;
  }
}
