import { Controller } from 'stimulus'

let timer = null;

export default class extends Controller {
  connect() {
  }

  close() {
    this.hide()
    setTimeout(() => {
      this.element.remove()
    }, 500)

  }

  show() {

    this.element.setAttribute(
      'style',
      "transition: 1s; transform:translate(0, 0);",
    )
    setTimeout(() => {
      this.close();
    }, 5000);
  }

  hide() {
    if (timer) clearTimeout(timer);
    this.element.setAttribute(
      'style',
      "transition: 1s; transform:translate(0px, -800px);",
    )
  }
}
