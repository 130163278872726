import { Controller } from "stimulus"
import Rails from "@rails/ujs";
import Swal from 'sweetalert2'

export default class extends Controller {
  static targets = ['form']
  static values = { question: String, option: String, sure: String, cancel: String, title: String }

  areYouSure(e){
    e.preventDefault();
    let _this = this
    Swal.fire({
      title: _this.titleValue,
      text: _this.questionValue,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3aad38',
      cancelButtonColor: '#d33',
      cancelButtonText: _this.cancelValue,
      confirmButtonText: _this.sureValue
      }).then((result) => {
        if (result.isConfirmed) {
            let form = _this.formTarget
            Rails.fire(form, 'submit');
        }
      })
  }
}
