import { Controller } from "stimulus"

// Setting the last scrollY value
let lastScroll = 0
export default class extends Controller {
  static targets = [ 'video' ]

  connect() {}

  check_scroll(){
    let videoHeight = this.videoTarget.offsetHeight + this.videoTarget.offsetTop;
    let top = this.videoTarget.offsetTop;
    let offset = Math.floor( top + (videoHeight / 2));
      // Checks if user has scrolled far enouh down to do something
      if(window.scrollY > offset){
        if(!this.videoTarget.classList.contains('video_scroll')) {
          this.videoTarget.classList.add('video_scroll')
        }
      }
      // Checks if user is far enouh up and moving upwards
      else if (window.scrollY < (offset /2 ) && lastScroll > window.scrollY) {
        this.videoTarget.classList.remove('video_scroll')
      }
      lastScroll = window.scrollY
  }
}
