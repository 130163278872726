import { Controller } from "stimulus"
import consumer from '../../channels/consumer'
import CableReady from "cable_ready"
export default class extends Controller {
  static values = { id: Number }

  initialize() {
    this.subscription()
  }
  disconnect() {
    this.subscription().unsubscribe()
    this.subscription().disconnected()
  }
  subscription() {
    let id = this.idValue
    if (this._subscription == undefined) {
      this._subscription = consumer.subscriptions.create({channel: "StreamsChannel", id: id }, {
        connected() {},
        disconnected() {},
        received(data) {
          if (data.cableReady) CableReady.perform(data.operations)
        }
      })
    }
    return this._subscription
  }
}
