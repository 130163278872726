import { Controller } from "stimulus"
import Tribute from 'tributejs'
import Rails from "@rails/ujs"
import { createPopperLite as createPopper} from "@popperjs/core"
import { Picker } from 'emoji-picker-element'

let active = false
export default class extends Controller {
  static targets = ['emojibutton', 'field', 'form', 'tooltip']
  connect() {
    this.editor = this.fieldTarget
    this.initializeTribute();
    this.fieldTarget.addEventListener("tribute-active-true", this.active)
    this.fieldTarget.addEventListener("tribute-active-false", this.notActive)
    this.fieldTarget.addEventListener('keydown', this.keyPress)
    this.addEmoji();

  }

  disconnect(){
    this.tribute.detach(this.fieldTarget)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)
  }

  initializeTribute() {
    this.tribute = new Tribute({
      allowSpaces: false,
      lookup: 'key',
      trigger: '@',
      noMatchTemplate: function () {
                      return '<span style:"visibility: hidden;"></span>';
                    },

      values: this.fetchUsers
    })
    this.tribute.attach(this.fieldTarget)
  }

  fetchUsers(text, callback) {
    fetch(`/mentions.json?query=${text}`).then(response => response.json()).then(users => callback(users)).catch(error => callback([]))
  }

  replaced(e) {
    let mention = e.detail.item.original
    let attachment = mention.content
    this.editor.insertAttachment(attachment)
    this.editor.insertString(" ")
  }

  _pasteHtml(html, startPos, endPos) {
    let position = this.editor.getPosition()
    this.editor.setSelectedRange([position - endPos, position])
    this.editor.deleteInDirection("backward")
  }

  active(){
    active = true
  }

  notActive(){
    active = false
  }

  submitTheForm() {
    Rails.fire(this.element, 'submit')
    this.formTarget.reset();
  }

  keyPress(e){
    let _this = this
    if(!active && e.key == "Enter"){
      e.preventDefault();
      Rails.fire(_this.parentNode, 'submit')
      this.parentNode.reset();
    }
  }

  addEmoji(){
    let _this = this
    const emoji = new Picker()
    this.tooltipTarget.appendChild(emoji)
    document.querySelector('emoji-picker').addEventListener('emoji-click', event => this.insertEmoji(event.detail.unicode));
  }

  insertEmoji(emoji){
    console.log(emoji)
    this.editor.value += emoji + '  '
    this.tooltipTarget.classList.toggle('hidden')
  }

  openEmojiSelector(){
    this.tooltipTarget.classList.toggle('hidden')

    const emoji = new Picker()
    createPopper(this.emojibuttonTarget, this.tooltipTarget, {placement: 'left', modifiers: {offset: {offset: '0 100px'}}})
  }
}
