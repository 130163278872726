import { Controller } from "stimulus"

export default class extends Controller {
  initialize(){
    this.resize(this.element)
  }
  resize() {
    this.element.style.height = "5px";
    this.element.style.height = (this.element.scrollHeight)+"px";
  }
}
