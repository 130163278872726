import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['link', 'spons', 'sponsor', 'sponsbutton']
  connect() {
  }

  enableLink(){
    this.linkTarget.classList.remove('hidden')
    this.hideSponsor()
  }

  disableLink(){
    this.linkTarget.classList.add('hidden')
    this.showSponsor()
  }

  toggleSponsor(){
    let state = this.sponsTarget.value
    console.log(state)
    state == 'true' ? this.disableSponsor() : this.enableSponsor()
  }

  enableSponsor(){
    this.sponsorTarget.classList.remove('hidden')
    this.sponsTarget.value = 'true'
  }

  disableSponsor(){
    this.sponsorTarget.classList.add('hidden')
    this.sponsTarget.value = 'false'
  }

  hideSponsor(){
    this.sponsbuttonTarget.classList.add('hidden')
  }

  showSponsor(){
    this.sponsbuttonTarget.classList.remove('hidden')
  }

}
