import { Controller } from "stimulus"
import { render, register } from 'timeago.js';
import nb_NO from 'timeago.js/lib/lang/nb_NO.js'

export default class extends Controller {
  static targets = ['time']
  connect() {
    let lang = document.getElementsByTagName('html')[0].getAttribute('lang');
    const node = this.timeTarget
    if( lang == 'no') {
      register('nb_NO', nb_NO)
      render(node, 'nb_NO', { minINterval: 3 })
    } else {
      render(node, { minINterval: 3 })
    }

  }
}
