import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["input"]
  connect(){}
  open(){
    this.inputTarget.click();
  }

  submit(){
    Rails.fire(this.element, 'submit')
  }
}
