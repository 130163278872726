import { Controller } from "stimulus"
import { FetchRequest } from '@rails/request.js'
import { Chart } from 'chart.js'

let data = [];

export default class extends Controller {
  static targets = [ "output", 'group', 'from', 'to' ]
  static values = {url:String}

  connect() {

    this.setUpChart();
  }

  async setUpChart() {
    let t = new Date(Date.now())
    let from_date = this.fromTarget.value + ' ' + (t.getHours()) +':'+t.getMinutes()+':00'
    let to_date = this.toTarget.value + ' ' + (t.getHours()) +':'+t.getMinutes()+':00'
    const request = new FetchRequest('get', this.urlValue, {query: {group_by: this.groupTarget.value, to_date: to_date, from: from_date}})
    const response = await request.perform()
    if (response.ok) {
      data = await response.json
      this.createChart();
    }
  }

  createChart(){
    this.chart();
  }

  chart(){
    if (this._chart == undefined) {
      this._chart = new Chart(this.outputTarget, {
        type: 'line',
        data: this.dataset(data),
        options: this.options()
      })
    } else {
      this._chart.data = this.dataset(data)
      this._chart.options = this.options()
      this._chart.update()
    }
    return this._chart
  }

  options(){
    let options = {
          responsive: true,
          maintainAspectRatio: true,
          animation: {
            easing: 'easeInOutQuad',
            duration: 1000
          },
          plugins: {
            legend: {display: false}
          },
          scales: {
            x: {display: this.showLegends(), grid: { color: '#32d8f5'}, ticks: { color: '#32d8f5'}},
            y: {display: true, grid: { color: '#fafafa'}, ticks: { color: '#32d8f5', stepSize: 25}}
          }
        }
    return options
  }

  dataset(chartdata){
    let data = {datasets: [{
          label: 'Users logged in',
          data: chartdata,
          strokeColor: "rgba(200, 128, 185, 1)",
          radius: 3,
          pointBackgroundColor: '#32d8f5',
          borderColor: "rgba(41, 128, 185, 0)",
          backgroundColor: this.gradientBackground(),
          tension: 0.4,
          fill: true
        }]};
      return data
  }

  gradientBackground(){
    let c = this.outputTarget;
    var ctx = c.getContext("2d")
    var grd = ctx.createLinearGradient(0, 400, 0, 0);
    grd.addColorStop(0, "#08b4d2");
    grd.addColorStop(0.5, "#08b4d2");
    return grd
  }

  showLegends(){
    if(this.groupTarget.value == 'hours'){
      return false
    } else {
      return true
    }
  }
}
